<template>
  <div>
    <pagination2 :option="post" url="/Record/learnRecord">
      <template v-slot:other="{ tableData }">
        <div class="flex-center user-info">
          <div class="flex-center user">
            <img class="photo" :src="tableData.user_photo" alt="" />
            <div class="name">{{ tableData.user_name }}</div>
          </div>
          <div class="flex-center right-box">
            <div class="studyDuration">
              <div class="flex-center text">
                <span class="t1">总学习时长</span>
                <helpIcon
                  class="t2"
                  prompt="指学员学习的总时长，不含在页面停留时长；学习行为包含播放视频、观看直播"
                ></helpIcon>
              </div>
              <div class="time">
                {{ tableData.learning_duration | formatTimeLength }}
              </div>
            </div>
            <div class="studyNumber">
              <div class="flex-center text">
                <span class="t1">总学习次数</span>
                <helpIcon
                  class="t2"
                  prompt="指学员学习的总次数，学习行为包含播放视频、观看直播；进入页面未触发学习行为无效"
                ></helpIcon>
              </div>
              <div class="time">{{ tableData.learning_num || '--' }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:default="{ tableData: { list } }">
        <div class="table-contain">
          <el-form :inline="true" class="form-inline" @submit.native.prevent>
            <el-form-item>
              <el-select v-model="post.user_from">
                <el-option
                  v-for="item in courseCateGory"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-table
            :data="list || []"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label="学习时间" prop="c_time">
              <template slot-scope="scope">
                {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column label="学习时长" prop="learning_duration">
              <template slot-scope="scope">
                {{ scope.row.learning_duration | formatTimeLength }}
              </template>
            </el-table-column>
            <el-table-column label="学习终端系统" prop="user_from">
              <template slot-scope="scope">
                {{ scope.row.user_from | userFrom }}
              </template>
            </el-table-column>
            <el-table-column
              label="类型"
              v-if="$route.query.type != 6 && $route.query.type != 7"
            >
              <template>
                {{ $route.query.type | getCourseStatus }}
              </template>
            </el-table-column>
            <el-table-column label="地理位置" prop="user_ip">
              <template slot-scope="scope">
                {{ scope.row.province }}&nbsp;&nbsp;{{ scope.row.city }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </pagination2>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'

export default {
  name: 'viewDetail',

  components: {
    helpIcon,
  },

  data() {
    const courseCateGory = [
      {
        name: '全部终端',
        id: 0,
      },
      {
        name: '小程序',
        id: 2,
      },
      {
        name: '电脑',
        id: 3,
      },
    ]

    const type = this.$route.query.type

    if (type != 2 && type != 5) {
      courseCateGory.splice(1, 0, {
        name: '微信H5',
        id: 1,
      })
    }

    // console.log(type, courseCateGory)

    return {
      post: {
        type,
        user_from: 0,
        id: this.$route.params.id,
        course_type: this.$route.query.course_type,
      },

      courseCateGory,
    }
  },

  filters: {
    getCourseStatus(value) {
      let text
      switch (Number(value)) {
        case 1:
          text = '普通模式'
          break
        case 2:
          text = '小班'
          break
        case 3:
          text = '录播'
          break
        case 4:
          text = '回放'
          break
        default:
          text = '极速模式'
      }
      return text
    },
    userFrom(value) {
      let text
      switch (Number(value)) {
        case 1:
          text = '微信H5'
          break
        case 2:
          text = '小程序'
          break
        default:
          text = '电脑'
      }
      return text
    },
  },
}
</script>
<style lang="scss" scoped>
.user-info {
  height: 87px;
  background: rgba(255, 255, 255, 1);
  .user {
    flex: 1;
    .photo {
      width: 50px;
      height: 50px;
      margin-left: 20px;
      border-radius: 50%;
    }
    .name {
      font-size: 16px;
      margin-left: 14px;
      line-height: 21px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .right-box {
    .studyDuration,
    .studyNumber {
      .text {
        .t1 {
          font-size: 13px;
          line-height: 17px;
          color: rgba(51, 51, 51, 1);
        }
        .t2 {
          width: 14px;
          height: 14px;
          display: block;
          margin-left: 4px;
          cursor: pointer;
          background: url('~@ass/img/1.2.9/ico_wh@2x.png');
          background-size: 100%;
        }
      }
      .time {
        font-size: 24px;
        font-weight: bold;
        line-height: 31px;
        margin-top: 6px;
        color: rgba(51, 51, 51, 1);
      }
    }
    .studyNumber {
      margin: 0 20px 0 110px;
    }
  }
}
.table-contain {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  ::v-deep .el-form {
    text-align: right;
  }
}
::v-deep .form-inline {
  .el-input {
    width: 120px;
  }
}
</style>
